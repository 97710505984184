import { Component } from '@angular/core';
import { StoreService } from '@core/store/store.service';
import { ToursState } from '@pages/tours-page/state/tours.state';

@Component({
  selector: 'app-tours-mobile-page',
  templateUrl: './tours-mobile-page.component.html',
  styleUrl: './tours-mobile-page.component.scss'
})
export class ToursMobilePageComponent {
  public readonly searchResults$ = this.storeService.select(ToursState.searchResults);
  constructor(private storeService: StoreService) {}
}
