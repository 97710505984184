import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CalcTourPackageRequest,
  CalcTourPackageResponse,
  GetOtherFlightsRequest,
  GetOtherFlightsResponse,
  SearchTourPackagesRequest,
  SearchTourPackagesResponse
} from '@appTypes/api.types';
import { environment } from '@env/environment';

@Injectable()
export class ToursApiService {
  private readonly apiEndPoint: string = environment.urls.api + '/TourPackages/';

  constructor(private httpClient: HttpClient) {}

  public searchTourPackages(payload: SearchTourPackagesRequest) {
    return this.httpClient.post<SearchTourPackagesResponse>(this.apiEndPoint + 'SearchTourPackages', payload);
  }
  public getOtherFlights(payload?: GetOtherFlightsRequest) {
    return this.httpClient.post<GetOtherFlightsResponse>(this.apiEndPoint + 'GetOtherFlights', payload);
  }
  public calcPackage(payload?: CalcTourPackageRequest) {
    return this.httpClient.post<CalcTourPackageResponse>(this.apiEndPoint + 'CalcPackage', {
      ...payload
      // refreshPrices: true
    });
  }
}
