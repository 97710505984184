import {
  CatalogueGetArrivalDistrictsListParams,
  CatalogueGetAvailableAirlinesListParams,
  CatalogueGetHotelsListParams,
  CatalogueGetFlightDateOptionsListParams
} from '@appTypes/api.types';
import { SearchTourPackagesPaging } from '../tours.types';
import { ITourPackage } from '@appTypes/redefined-types';

export class toursReset {
  static type = '[Tours] Reset';
  constructor() {}
}
export class toursGetAvailableAirlines {
  static readonly type = '[Tours] GetAvailableAirlines';
  constructor(
    public params?: CatalogueGetAvailableAirlinesListParams,
    public refresh = false
  ) {}
}

export class toursGetDestinationCountries {
  static readonly type = '[Tours] GetDestinationCountries';
  constructor(public refresh = false) {}
}

export class toursGetArrivalDistricts {
  static readonly type = '[Tours] GetArrivalDistricts';
  constructor(
    public params: CatalogueGetArrivalDistrictsListParams,
    public refresh = false
  ) {}
}

export class toursGetHotels {
  static readonly type = '[Tours] GetHotels';
  constructor(
    public params: CatalogueGetHotelsListParams,
    public refresh = false
  ) {}
}
export class toursLoadFlightDateOptions {
  static readonly type = '[Tours] GetFlightDateOptions';
  constructor(
    public params: CatalogueGetFlightDateOptionsListParams,
    public identifier: string
  ) {}
}

export class toursSearchTourPackages {
  static readonly type = '[Tours] SearchTourPackages';
  constructor(public paging?: SearchTourPackagesPaging) {}
}

export class toursOpenDetails {
  static readonly type = '[Tours] OpenDetails';
  constructor(public data: ITourPackage) {}
}

export class toursResetSearchResult {
  static readonly type = '[Tours] toursResetSearchResult';
}
