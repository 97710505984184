import {
  Airline,
  GetCountriesResponse,
  GetFlightDateOptionsResponse,
  GetOtherFlightsResponse,
  Hotel,
  SearchTourPackagesRequest,
  SearchTourPackagesResponse
} from '@appTypes/api.types';
import { DistrictsTreeItem } from '@appTypes/redefined-types';
import { RequestStateModel, StateFormModel } from '@core/store/store.models';

export class ToursFlightAvailabilityModel {
  data = new RequestStateModel<GetFlightDateOptionsResponse>();
  identifier!: string;
}
export class ToursSearchFormDataModel {
  availableAirlines = new RequestStateModel<Airline[]>();
  arrivalDistricts = new RequestStateModel<DistrictsTreeItem[]>();
  hotels = new RequestStateModel<Hotel[]>();
  destinationCountries = new RequestStateModel<GetCountriesResponse>();
  flightAvailabilities: ToursFlightAvailabilityModel[] = [];
}

export class ToursStateModel {
  searchForm = new StateFormModel<SearchTourPackagesRequest>();
  searchFormData = new ToursSearchFormDataModel();
  /*  */
  searchResults = new RequestStateModel<SearchTourPackagesResponse>();
  /*  */
  selectedTourOtherPackages = new RequestStateModel<GetOtherFlightsResponse>();
}
