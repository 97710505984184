@if (isLoading && !package) {
  <div class="tour-details-container">
    <mat-progress-bar mode="indeterminate" />
  </div>
}
@if (package) {
  <div class="tour-details-container relative">
    <div class="tour-details-body px-4 py-3 pb-24">
      @if (package.hotel) {
        <app-kz-mobile-hotel-card [hotel]="hotelData" />
      }
      @if (altPackages) {
        <div class="mt-6 mb-10">
          <app-kz-mobile-select
            #otherFlightSelector
            optionValue="id"
            placeholder="Other Flights"
            class="hidden"
            icon="airplane-outline-2"
            [options]="altPackages"
            [typedToken]="packageTypedToken"
            [value]="package.id"
            (selectedOptionData)="onOtherFlightSelect($event)"
          >
            <ng-template #templateOptionLabel let-option>
              <ng-container *ngTemplateOutlet="flightsSelectOptionTemplateRef; context: { option }" />
            </ng-template>
          </app-kz-mobile-select>
          <button
            mat-flat-button
            color="primary"
            class="w-full"
            type="submit"
            (click)="otherFlightSelector.onOpenOptions()"
          >
            {{ 'Other Flights' | translate }}
          </button>
        </div>
      }
      <form class="flights mb-4 mt-8" [formGroup]="formGroup">
        <div class="flights-item">
          <div class="flights-logo-container">
            <div class="flex">
              @if (
                package.flight?.departureSegments?.at(0)?.airlineCode | runFunction: this.getAirlineIconFn;
                as icon
              ) {
                <mat-icon class="svg-airline" [svgIcon]="icon" />
              }
              <div class="text-kz-primary-dark font-medium ml-3">
                {{ package.flight?.departureSegments?.at(0)?.flightCode }}
              </div>
              <app-kz-flight-class
                [styleClass]="'ml-3'"
                [flightClass]="package.flight?.departureSegments?.at(0)?.class"
                [ticketsLeft]="package.flight?.departureTicketsLeft"
                [isBlock]="package.flight?.isBlock"
              />
            </div>
            <div class="flights-plan pt-3">
              <div class="flights-row">
                <span class="country-index">{{ package.flight?.departureSegments?.at(0)?.departureAirportCode }}</span>
                <span class="separator"></span>
                <span class="airplane-icon">
                  <mat-icon svgIcon="airplane-outline-2" color="primary" />
                </span>
                <span class="separator"></span>
                <span class="country-index">{{ package.flight?.departureSegments?.at(-1)?.arrivalAirportCode }} </span>
              </div>
              <div class="flights-row pt-2">
                <span class="country-name">{{ package.flight?.departureSegments?.at(-1)?.departureCityName }}</span>
                <span class="country-name">{{ package.flight?.departureSegments?.at(-1)?.arrivalCityName }}</span>
              </div>
              <div class="flights-row pt-2">
                <span> {{ package.flight?.departureSegments?.at(0)?.departureFlightDate | date: 'short' }} </span>
                <span> {{ package.flight?.departureSegments?.at(0)?.arrivalFlightDate | date: 'short' }} </span>
              </div>
            </div>
            @if (!package.flight?.totalBaggage) {
              <ng-container formArrayName="passengersForwardBaggages">
                @for (
                  passengersForwardBagageForm of passengersForwardBaggagesFormArray.controls;
                  track index;
                  let index = $index
                ) {
                  <div class="py-2" [formGroupName]="index">
                    <app-kz-desktop-select
                      placeholder="Bagage"
                      optionValue="baggageCode"
                      formControlName="baggageCode"
                      class="other-package-selector"
                      [typedToken]="bagageTypedToken"
                      [options]="(passengers && passengers[index] && passengers[index].forwardBaggages) || []"
                      (selectedOptionData)="onBagageChange($event)"
                    >
                      <ng-template #templateSelected let-option>
                        <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                      </ng-template>
                      <ng-template #templateOptionLabel let-option>
                        <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                      </ng-template>
                    </app-kz-desktop-select>
                  </div>
                }
              </ng-container>
            }
          </div>
          <div class="flights-logo-container">
            <div class="flex">
              @if (package.flight?.returnSegments?.at(0)?.airlineCode | runFunction: this.getAirlineIconFn; as icon) {
                <mat-icon class="svg-airline" [svgIcon]="icon" />
              }
              <div class="text-kz-primary-dark font-medium ml-3">
                {{ package.flight?.returnSegments?.at(0)?.flightCode }}
              </div>
              <app-kz-flight-class
                [styleClass]="'ml-3'"
                [flightClass]="package.flight?.returnSegments?.at(0)?.class"
                [ticketsLeft]="package.flight?.returnTicketsLeft"
                [isBlock]="package.flight?.isBlock"
              />
            </div>
            <div class="flights-plan pt-3">
              <div class="flights-row">
                <span class="country-index">{{ package.flight?.returnSegments?.at(0)?.departureAirportCode }}</span>
                <span class="separator"></span>
                <span class="airplane-icon">
                  <mat-icon svgIcon="airplane-outline-2" color="primary" />
                </span>
                <span class="separator"></span>
                <span class="country-index">{{ package.flight?.returnSegments?.at(-1)?.arrivalAirportCode }} </span>
              </div>
              <div class="flights-row pt-2">
                <span class="country-name">{{ package.flight?.returnSegments?.at(-1)?.departureCityName }}</span>
                <span class="country-name"> {{ package.flight?.returnSegments?.at(0)?.arrivalCityName }} </span>
              </div>
              <div class="flights-row pt-2">
                <span> {{ package.flight?.returnSegments?.at(0)?.departureFlightDate | date: 'short' }} </span>
                <span> {{ package.flight?.returnSegments?.at(0)?.arrivalFlightDate | date: 'short' }} </span>
              </div>
            </div>

            @if (!package.flight?.totalBaggage) {
              <ng-container formArrayName="passengersBackwardBaggages">
                @for (
                  passengersBackwardBaggageForm of passengersBackwardBaggagesFormArray.controls;
                  track index;
                  let index = $index
                ) {
                  <div class="py-2" [formGroupName]="index">
                    <app-kz-desktop-select
                      placeholder="Bagage"
                      optionValue="baggageCode"
                      formControlName="baggageCode"
                      class="other-package-selector"
                      [typedToken]="bagageTypedToken"
                      [options]="(passengers && passengers[index] && passengers[index].backwardBaggages) || []"
                      (selectedOptionData)="onBagageChange($event)"
                    >
                      <ng-template #templateSelected let-option>
                        <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                      </ng-template>
                      <ng-template #templateOptionLabel let-option>
                        <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                      </ng-template>
                    </app-kz-desktop-select>
                  </div>
                }
              </ng-container>
            }
          </div>
        </div>
      </form>

      @for (extraService of package.extraServices; track extraService.extraServiceCode) {
        <div class="mt-3">
          @for (extraService of package.extraServices; track extraService.extraServiceCode) {
            @if (extraService.extraServiceCode && extraService.extraServiceName) {
              <h3 class="text-kz-primary">
                {{ extraService.extraServiceName | translate }}
              </h3>
              <app-kz-desktop-select
                optionLabel="displayName"
                optionValue="extraServicePriceCode"
                placeholder="Without extra services"
                [options]="extraService.prices ?? []"
                [typedToken]="extraServicePriceTypedToken"
                [value]="extraService.includedPriceCode"
                [showClear]="!extraService.isMandatory"
                (selectedOptionData)="onExtraServiceSelect(extraService.extraServiceCode, $event)"
              >
                <ng-template #templateSelected let-option>
                  <div class="text-nowrap text-ellipsis overflow-hidden">
                    {{ option.displayName ?? '' | translate }}
                  </div>
                </ng-template>
                <ng-template #templateOptionLabel let-option>
                  {{ option.displayName ?? '' | translate }}
                  <!-- 
                @note - removed - https://app.clickup.com/t/2468594/REQ-6046
                @if (option.extraPrice) {
                  <span>
                    ({{ 'For adults' | translate }}: {{ option.extraPrice | kzPrice: package.priceCurrency }})</span
                  >
                }
                 -->
                </ng-template>
              </app-kz-desktop-select>
              @if (
                { includedPriceCode: extraService.includedPriceCode, extraServiceCode: extraService.extraServiceCode }
                  | runFunction: getSelectedExtraServiceDataFn;
                as extraServicePrice
              ) {
                <div class="flex flex-col gap-2 my-2">
                  <!--                   
                  @note  - https://app.clickup.com/t/2468594/REQ-6172

                  @if (extraServicePrice.extraServicePriceCode) {
                    <div>
                      <span class="text-kz-primary font-bold">{{ 'Promo code' | translate }}:</span>
                      {{ extraServicePrice.extraServicePriceCode }}
                    </div>
                  } -->

                  @if (extraServicePrice.additionalInfo || extraServicePrice?.displayName) {
                    <div>
                      <span class="text-kz-primary font-bold">{{ 'Description' | translate }}:</span>
                      {{ extraServicePrice.additionalInfo ?? extraServicePrice?.displayName }}
                    </div>
                  }
                  @if (extraServicePrice.restrictionInfo) {
                    <div>
                      <span class="text-kz-primary font-bold">{{ 'Term' | translate }}:</span>
                      {{ extraServicePrice.restrictionInfo }}
                    </div>
                  }
                </div>
              }
            }
          }
        </div>
      }
      <div class="bg-kz-layout-featured py-2">
        <div class="details-list px-2">
          @if (package.flight?.totalBaggage && passengers?.length) {
            @if (passengers?.[0]?.forwardBaggages?.[0]; as forwardBaggage) {
              <div>
                <mat-checkbox
                  color="primary"
                  [checked]="forwardBaggage?.included"
                  [disabled]="forwardBaggage?.included"
                  (change)="onCheckBagage($event)"
                >
                  <span style="font-size: 0.9em"
                    >{{ forwardBaggage?.description }}
                    {{ forwardBaggage?.price ? (forwardBaggage?.price | kzPrice: forwardBaggage?.currency) : '' }}
                  </span>
                </mat-checkbox>
              </div>
            }
          }
          <div class="flex flex-row gap-3">
            <mat-icon svgIcon="car" class="mt-1" color="primary-dark" />
            <div>
              {{ 'Transfer' | translate }}: <span class="font-semibold">{{ 'Group' | translate }}</span>
              <div class="text-secondary">
                {{ 'Airport' | translate }} - {{ 'Hotel' | translate }} - {{ 'Airport' | translate }}
              </div>
            </div>
          </div>
          <div class="flex flex-row gap-3" [ngStyle]="{ display: package.insurancePrice ? 'block' : 'none' }">
            <mat-checkbox
              color="primary"
              [disabled]="!package.insurancePrice?.optional"
              [(ngModel)]="includedInPackagePrice"
              (ngModelChange)="onchange($event)"
            >
              <span style="font-size: 0.9em">
                {{ package.insurancePrice?.name }}
                <!-- @note - no need at this moment
            ({{ package.insurancePrice?.price | kzPrice: package.priceCurrency }}) -->
                {{ package.insurancePrice?.actualStartDate | date: 'dd.MM.YYYY' }} -
                {{ package.insurancePrice?.actualEndDate | date: 'dd.MM.YYYY' }}
              </span>
            </mat-checkbox>
          </div>
        </div>

        <div class="price-details p-2 relative">
          <div>
            {{ 'Amount to Pay' | translate }}
            <div class="font-bold">
              {{ (data.totalPrice ?? 0) - (data.agentCommissionAmount ?? 0) | kzPrice: data.priceCurrency }}
            </div>
          </div>
          <div>
            {{ "Agent's Commission" | translate }}
            <div class="font-bold">{{ package.agentCommissionPercent }}%</div>
          </div>
          <div>
            {{ 'Commission Amount' | translate }}
            <div class="font-bold">{{ package.agentCommissionAmount }}%</div>
          </div>
          <div class="text-kz-primary-dark font-bold">
            {{ 'Total' | translate }}
            <div>{{ package.totalPrice ?? 0 | kzPrice: package.priceCurrency }}</div>
          </div>
        </div>
      </div>
      @if (package.flight?.departureSegments?.at(0)?.airlineCode === 'K@') {
        <h3 class="flex items-center">
          <mat-icon class="svg-airline" svgIcon="airline-logo-k@" />
          <a href="https://flyarystan.com/fare-types" class="text-kz-danger" target="_blank"
            >FlyArystan Important Notification!</a
          >
        </h3>
      }
    </div>
    <div class="dynamic-modal-footer">
      @if (isLoading) {
        <div class="absolute top-0 left-0 w-full">
          <mat-progress-bar mode="indeterminate" />
        </div>
      }
      <div class="dynamic-modal-actions">
        <button
          mat-flat-button
          color="primary"
          class="w-1/2"
          type="submit"
          enterkeyhint="go"
          [loading]="isLoading"
          [disabled]="!allowAddToCart"
          (click)="onAddCart()"
        >
          <mat-icon svgIcon="cart" />
          {{ package.totalPrice ?? 0 | kzPrice: package.priceCurrency }}
        </button>
      </div>
    </div>
    <ng-template #flightsSelectOptionTemplateRef let-option="option" [typedTemplate]="flightSelectorTemplateTypedToken">
      <div class="flights-select-option py-1">
        <!-- departure -->
        <div>
          <span class="airplane-icon">
            <mat-icon svgIcon="airplane-outline-2" color="primary" />
          </span>
          @if (option?.flight?.departureSegments?.at(0)?.airlineCode | runFunction: this.getAirlineIconFn; as icon) {
            <mat-icon class="svg-airline" [svgIcon]="icon" />
          }
          <div>
            {{ option?.flight?.departureSegments?.at(0)?.flightCode }}
            {{ option?.flight?.departureSegments?.at(0)?.departureAirportCode }}
            <span class="font-semibold">{{
              option?.flight?.departureSegments?.at(0)?.departureFlightDate | date: 'dd.MM HH:mm'
            }}</span>
            -
            {{ option?.flight?.departureSegments?.at(-1)?.arrivalAirportCode }}
            <span class="font-semibold">{{
              option?.flight?.departureSegments?.at(-1)?.arrivalFlightDate | date: 'dd.MM HH:mm'
            }}</span>
            <app-kz-flight-class
              [flightClass]="option.flight?.departureSegments?.at(0)?.class"
              [styleClass]="'py-[0.1rem!important]'"
              [ticketsLeft]="option.flight?.departureTicketsLeft"
              [isBlock]="option.flight?.isBlock"
            />
          </div>
        </div>
        <!-- return -->
        <div>
          <span class="airplane-icon inverse">
            <mat-icon svgIcon="airplane-outline-2" color="primary" />
          </span>
          @if (option?.flight?.returnSegments?.at(0)?.airlineCode | runFunction: this.getAirlineIconFn; as icon) {
            <mat-icon class="svg-airline" [svgIcon]="icon" />
          }
          <div>
            {{ option?.flight?.returnSegments?.at(0)?.flightCode }}
            {{ option?.flight?.returnSegments?.at(0)?.departureAirportCode }}
            <span class="font-semibold">{{
              option?.flight?.returnSegments?.at(0)?.departureFlightDate | date: 'dd.MM HH:mm'
            }}</span>
            -
            {{ option?.flight?.returnSegments?.at(-1)?.arrivalAirportCode }}
            <span class="font-semibold">{{
              option?.flight?.returnSegments?.at(-1)?.arrivalFlightDate | date: 'dd.MM HH:mm'
            }}</span>
            <app-kz-flight-class
              [flightClass]="option.flight?.returnSegments?.at(0)?.class"
              [styleClass]="'py-[0.1rem!important]'"
              [ticketsLeft]="option.flight?.returnTicketsLeft"
              [isBlock]="option.flight?.isBlock"
            />
          </div>
        </div>
        @if (option.priceDifference) {
          <div
            class="flex items-center w-full justify-center"
            [ngClass]="{ 'text-kz-success': option.priceDifference < 0, 'text-kz-danger': option.priceDifference > 0 }"
          >
            {{ option.priceDifference > 0 ? '+' : '' }}{{ option.priceDifference | kzPrice: option.priceCurrency }}
          </div>
        }
      </div>
    </ng-template>
  </div>
}

<!-- Templates -->
<ng-template #bagageSelectOptionTemplateRef let-option="option" [typedTemplate]="bagageSelectorTemplateTypedToken">
  <div class="flex flex-row justify-between w-full">
    {{ option.description }} {{ option.price ?? 0 | kzPrice: option.currency }}
  </div>
</ng-template>
