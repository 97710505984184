import { Injectable } from '@angular/core';
import { TourPackage } from '@appTypes/api.types';
import { IS_MOBILE } from '@core/misc/misc.utils';
import { StoreService } from '@core/store/store.service';
import { TourDetailsDesktopComponent } from '@pages/tours-page/desktop/tour-details-desktop/tour-details-desktop.component';
import { ToursSearchFormService } from '@pages/tours-page/services/tours-search-form.service';
import { KzDesktopLoadingWindowService } from '@shared/components/desktop/kz-desktop-loading-window/kz-desktop-loading-window.service';
import { ConfirmService } from '@shared/modules/confirm/confirm.service';
import { DynamicModalService } from '@shared/modules/dynamic-modal/dynamic-modal.service';
import { CatalogueApiService } from '@shared/services/api/catalogue-api.service';
import { TourDetailsMobileComponent } from '../mobile/tour-details-mobile/tour-details-mobile.component';
import { ToursApiService } from './tours-api.service';

@Injectable()
export class ToursCommonService {
  constructor(
    private dynamicModalService: DynamicModalService,
    public catalogueApi: CatalogueApiService,
    public api: ToursApiService,
    public loadingWindow: KzDesktopLoadingWindowService,
    public storeService: StoreService,
    public confirmService: ConfirmService,
    public toursSearchFormService: ToursSearchFormService
  ) {}

  public openDetails(data: TourPackage) {
    if (IS_MOBILE)
      return this.dynamicModalService.open({
        title: 'Particulars',
        component: TourDetailsMobileComponent,
        height: '85vh',
        data
      });
    else
      return this.dynamicModalService.open({
        title: 'Particulars',
        component: TourDetailsDesktopComponent,
        height: '80vh',
        data
      });
  }
}
