@if (isLoading && !package) {
  <div class="tour-details-container">
    <mat-progress-bar mode="indeterminate" />
  </div>
}
@if (package) {
  <div class="tour-details-container">
    <div class="tour-details-body px-4 py-3">
      @if (package.hotel) {
        <app-kz-desktop-hotel-card [hotel]="hotelData" />
      }
      @if (altPackages) {
        <div class="my-2">
          <app-kz-desktop-select
            placeholder="Other Flights"
            optionValue="id"
            class="other-package-selector"
            [typedToken]="packageTypedToken"
            [options]="altPackages"
            [value]="package.id"
            (selectedOptionData)="onOtherFlightSelect($event)"
          >
            <ng-template #templateSelected let-option>
              <ng-container *ngTemplateOutlet="flightsSelectOptionTemplateRef; context: { option, selectView: true }" />
            </ng-template>
            <ng-template #templateOptionLabel let-option>
              <ng-container *ngTemplateOutlet="flightsSelectOptionTemplateRef; context: { option }" />
            </ng-template>
          </app-kz-desktop-select>
        </div>
      }
      <form class="flights my-2" [formGroup]="formGroup">
        <div class="grid grid-cols-2 gap-6">
          <div class="flights-logo-container">
            <div class="flex">
              @if (
                package.flight?.departureSegments?.at(0)?.airlineCode | runFunction: this.getAirlineIconFn;
                as icon
              ) {
                <mat-icon class="svg-airline" [svgIcon]="icon" />
              }
              <div class="text-kz-primary font-medium flex items-center gap-1">
                <div>
                  {{ package.flight?.departureSegments?.at(0)?.flightCode }}
                </div>
                <app-kz-flight-class
                  [flightClass]="package.flight?.departureSegments?.at(0)?.class"
                  [ticketsLeft]="package.flight?.departureTicketsLeft"
                  [isBlock]="package.flight?.isBlock"
                />
              </div>
            </div>
            <div class="flights-plan pt-3">
              <div class="flights-row">
                <span class="country-index">{{ package.flight?.departureSegments?.at(0)?.departureAirportCode }} </span>
                <span class="separator"></span>
                <span class="airplane-icon">
                  <mat-icon svgIcon="airplane-outline-2" color="primary" />
                </span>
                <span class="separator"></span>
                <span class="country-index">{{ package.flight?.departureSegments?.at(-1)?.arrivalAirportCode }} </span>
              </div>
              <div class="flights-row">
                <span class="country-name">
                  {{ package.flight?.departureSegments?.at(0)?.departureCityName }}
                </span>
                <span class="country-name">
                  {{ package.flight?.departureSegments?.at(-1)?.arrivalCityName }}
                </span>
              </div>
              <div class="flights-row">
                <span>
                  {{ package.flight?.departureSegments?.at(0)?.departureFlightDate | date: 'short' }}
                </span>
                <span>
                  {{ package.flight?.departureSegments?.at(-1)?.arrivalFlightDate | date: 'short' }}
                </span>
              </div>
            </div>
            @if (!package.flight?.totalBaggage) {
              <ng-container formArrayName="passengersForwardBaggages">
                @for (
                  passengersForwardBagageForm of passengersForwardBaggagesFormArray.controls;
                  track index;
                  let index = $index
                ) {
                  <div class="py-2" [formGroupName]="index">
                    <app-kz-desktop-select
                      placeholder="Baggage not included"
                      optionValue="baggageCode"
                      formControlName="baggageCode"
                      class="other-package-selector"
                      [typedToken]="bagageTypedToken"
                      [options]="(passengers && passengers[index] && passengers[index].forwardBaggages) || []"
                      (selectedOptionData)="onBagageChange($event)"
                    >
                      <ng-template #templateSelected let-option>
                        <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                      </ng-template>
                      <ng-template #templateOptionLabel let-option>
                        <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                      </ng-template>
                    </app-kz-desktop-select>
                  </div>
                }
              </ng-container>
            }
          </div>

          <div class="flights-logo-container">
            <div class="flex">
              @if (package.flight?.returnSegments?.at(0)?.airlineCode | runFunction: this.getAirlineIconFn; as icon) {
                <mat-icon class="svg-airline" [svgIcon]="icon" />
              }
              <div class="text-kz-primary font-medium flex items-center gap-1">
                <div>
                  {{ package.flight?.returnSegments?.at(0)?.flightCode }}
                </div>
                <app-kz-flight-class
                  [flightClass]="package.flight?.returnSegments?.at(0)?.class"
                  [ticketsLeft]="package.flight?.returnTicketsLeft"
                  [isBlock]="package.flight?.isBlock"
                />
              </div>
            </div>
            <div class="flights-plan pt-3">
              <div class="flights-row">
                <span class="country-index">{{ package.flight?.returnSegments?.at(0)?.departureAirportCode }} </span>
                <span class="separator"></span>
                <span class="airplane-icon">
                  <mat-icon svgIcon="airplane-outline-2" color="primary" />
                </span>
                <span class="separator"></span>
                <span class="country-index">{{ package.flight?.returnSegments?.at(-1)?.arrivalAirportCode }} </span>
              </div>
              <div class="flights-row">
                <span class="country-name">
                  {{ package.flight?.returnSegments?.at(0)?.departureCityName }}
                </span>
                <span class="country-name">
                  {{ package.flight?.returnSegments?.at(-1)?.arrivalCityName }}
                </span>
              </div>
              <div class="flights-row">
                <span>
                  {{ package.flight?.returnSegments?.at(0)?.departureFlightDate | date: 'short' }}
                </span>
                <span>
                  {{ package.flight?.returnSegments?.at(-1)?.arrivalFlightDate | date: 'short' }}
                </span>
              </div>
            </div>

            @if (!package.flight?.totalBaggage) {
              <ng-container formArrayName="passengersBackwardBaggages">
                @for (
                  passengersBackwardBaggageForm of passengersBackwardBaggagesFormArray.controls;
                  track index;
                  let index = $index
                ) {
                  <div class="py-2" [formGroupName]="index">
                    <app-kz-desktop-select
                      placeholder="Baggage not included"
                      optionValue="baggageCode"
                      formControlName="baggageCode"
                      class="other-package-selector"
                      [typedToken]="bagageTypedToken"
                      [options]="(passengers && passengers[index] && passengers[index].backwardBaggages) || []"
                      (selectedOptionData)="onBagageChange($event)"
                    >
                      <ng-template #templateSelected let-option>
                        <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                      </ng-template>
                      <ng-template #templateOptionLabel let-option>
                        <ng-container *ngTemplateOutlet="bagageSelectOptionTemplateRef; context: { option }" />
                      </ng-template>
                    </app-kz-desktop-select>
                  </div>
                }
              </ng-container>
            }
          </div>
        </div>
      </form>
      @if (package.extraServices?.length) {
        <div>
          @for (extraService of package.extraServices; track extraService.extraServiceCode) {
            @if (extraService.extraServiceCode && extraService.extraServiceName) {
              <h3 class="text-kz-primary">
                {{ extraService.extraServiceName | translate }}
              </h3>
              <app-kz-desktop-select
                optionLabel="displayName"
                optionValue="extraServicePriceCode"
                placeholder="Without extra services"
                class="mb-2"
                [options]="extraService.prices ?? []"
                [typedToken]="extraServicePriceTypedToken"
                [value]="extraService.includedPriceCode"
                [showClear]="!extraService.isMandatory"
                (selectedOptionData)="onExtraServiceSelect(extraService.extraServiceCode, $event)"
              >
                <ng-template #templateSelected let-option>
                  <div class="flex flex-row gap-2 justify-between">
                    <div class="text-nowrap text-ellipsis overflow-hidden" style="max-width: 500px">
                      {{ option.displayName ?? '' | translate }}
                    </div>
                    <div>
                      <div class="bg-kz-layout-featured text-kz-danger px-2 ml-2 text-nowrap">
                        + {{ option.price | kzPrice: option.currency ?? package.priceCurrency }}
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template #templateOptionLabel let-option>
                  <div class="flex flex-row gap-2 justify-between w-full">
                    <div>
                      {{ option.displayName ?? '' | translate }}
                    </div>
                    <div>
                      <div class="bg-kz-layout-featured text-kz-danger px-2 ml-2 text-nowrap">
                        + {{ option.price | kzPrice: option.currency ?? package.priceCurrency }}
                      </div>
                    </div>
                  </div>
                  <!-- 
                  @note - removed - https://app.clickup.com/t/2468594/REQ-6046
                  @if (option.extraPrice) {
                    <span>
                      ({{ 'For adults' | translate }}: {{ option.extraPrice | kzPrice: package.priceCurrency }})</span
                    >
                  }
                   -->
                </ng-template>
              </app-kz-desktop-select>
              @if (
                { includedPriceCode: extraService.includedPriceCode, extraServiceCode: extraService.extraServiceCode }
                  | runFunction: getSelectedExtraServiceDataFn;
                as extraServicePrice
              ) {
                <div class="flex flex-col gap-2 my-2">
                  <!-- 
                @note  - https://app.clickup.com/t/2468594/REQ-6172
                @if (extraServicePrice.extraServicePriceCode) {
                  <div>
                    <span class="text-kz-primary font-bold">{{ 'Promo code' | translate }}:</span>
                    {{ extraServicePrice.extraServicePriceCode }}
                  </div>
                } -->
                  @if (extraServicePrice.additionalInfo || extraServicePrice?.displayName) {
                    <div>
                      <span class="text-kz-primary font-bold">{{ 'Description' | translate }}:</span>
                      {{ extraServicePrice.additionalInfo ?? extraServicePrice?.displayName }}
                    </div>
                  }
                  @if (extraServicePrice.restrictionInfo) {
                    <div>
                      <span class="text-kz-primary font-bold">{{ 'Term' | translate }}:</span>
                      {{ extraServicePrice.restrictionInfo }}
                    </div>
                  }
                </div>
              }
            }
          }
        </div>
      }
      <div class="bg-kz-layout-featured p-2">
        <div class="details-list">
          @if (package.flight?.totalBaggage && passengers?.length) {
            @if (passengers?.[0]?.forwardBaggages?.[0]; as forwardBaggage) {
              <div>
                <mat-checkbox
                  color="primary"
                  [checked]="forwardBaggage?.included"
                  [disabled]="forwardBaggage?.included"
                  (change)="onCheckBagage($event)"
                >
                  <span style="font-size: 0.9em"
                    >{{ forwardBaggage?.description }}
                    {{ forwardBaggage?.price ? (forwardBaggage?.price | kzPrice: forwardBaggage?.currency) : '' }}
                  </span>
                </mat-checkbox>
              </div>
            }
          }
          <div>
            <mat-icon svgIcon="car" color="primary" />
            {{ 'Transfer' | translate }}:
            <span class="font-medium">{{ 'Group' | translate }},</span>
            {{ 'Airport' | translate }} - {{ 'Hotel' | translate }} - {{ 'Airport' | translate }}
          </div>
          <div [ngStyle]="{ display: package.insurancePrice ? 'block' : 'none' }">
            <mat-checkbox
              color="primary"
              [disabled]="!package.insurancePrice?.optional"
              [(ngModel)]="includedInPackagePrice"
              (ngModelChange)="onchange($event)"
            >
              <span style="font-size: 0.9em">
                {{ package.insurancePrice?.name }}
                <!-- @note - no need at this moment
              ({{ package.insurancePrice?.price | kzPrice: package.priceCurrency }}) -->
                {{ package.insurancePrice?.actualStartDate | date: 'dd.MM.YYYY' }} -
                {{ package.insurancePrice?.actualEndDate | date: 'dd.MM.YYYY' }}
              </span>
            </mat-checkbox>
          </div>
        </div>
        <div class="price-details p-2 relative">
          <div>
            {{ 'Amount to Pay' | translate }}:
            <div class="font-bold ml-1">
              {{ (package.totalPrice ?? 0) - (package.agentCommissionAmount ?? 0) | kzPrice: package.priceCurrency }}
            </div>
          </div>
          <div>
            {{ "Agent's Commission" | translate }}:
            <div class="font-bold ml-1">{{ package.agentCommissionPercent }}%</div>
          </div>
          <div>
            {{ 'Commission Amount' | translate }}:
            <div class="font-bold ml-1">{{ package.agentCommissionAmount | kzPrice }}</div>
          </div>
          <div class="text-kz-primary font-bold">
            {{ 'Total' | translate }}:
            <div class="ml-1">
              {{ package.totalPrice ?? 0 | kzPrice: package.priceCurrency }}
            </div>
          </div>
        </div>
      </div>

      @if (package.flight?.departureSegments?.at(0)?.airlineCode === 'K@') {
        <h3 class="flex items-center">
          <mat-icon class="svg-airline" svgIcon="airline-logo-k@" />
          <a href="https://flyarystan.com/fare-types" class="text-kz-danger" target="_blank"
            >FlyArystan Important Notification!</a
          >
        </h3>
      }
    </div>
    <ng-template #modalFooterTemplateRef>
      @if (isLoading) {
        <div class="w-full">
          <mat-progress-bar mode="indeterminate" />
        </div>
      }
      <div class="py-3 flex items-center justify-center">
        <button
          mat-flat-button
          color="primary"
          class="medium-size mt-2 w-[15rem!important]"
          type="submit"
          [loading]="isLoading"
          [disabled]="!allowAddToCart"
          (click)="onAddCart()"
        >
          <mat-icon svgIcon="cart" />
          {{ package.totalPrice ?? 0 | kzPrice: package.priceCurrency }}
        </button>
      </div>
    </ng-template>

    <ng-template
      #flightsSelectOptionTemplateRef
      let-option="option"
      let-selectView="selectView"
      [typedTemplate]="flightSelectorTemplateTypedToken"
    >
      <div class="flex flex-row justify-between w-full">
        <div class="flights-select-option py-1">
          <!-- departure -->
          <div>
            <span class="airplane-icon">
              <mat-icon svgIcon="airplane-outline-2" color="primary" />
            </span>
            @if (option?.flight?.departureSegments?.at(0)?.airlineCode | runFunction: this.getAirlineIconFn; as icon) {
              <mat-icon class="svg-airline" [svgIcon]="icon" />
            }
            <div>
              {{ option?.flight?.departureSegments?.at(0)?.flightCode }}
              {{ option?.flight?.departureSegments?.at(0)?.departureAirportCode }}
              <span class="font-semibold">{{
                option?.flight?.departureSegments?.at(0)?.departureFlightDate | date: 'dd.MM HH:mm'
              }}</span>
              -
              {{ option?.flight?.departureSegments?.at(-1)?.arrivalAirportCode }}
              <span class="font-semibold">{{
                option?.flight?.departureSegments?.at(-1)?.arrivalFlightDate | date: 'dd.MM HH:mm'
              }}</span>
            </div>
            <app-kz-flight-class
              [flightClass]="option.flight?.departureSegments?.at(0)?.class"
              [ticketsLeft]="option.flight?.departureTicketsLeft"
              [isBlock]="option.flight?.isBlock"
            />
          </div>
          <!-- return -->
          <div>
            <span class="airplane-icon inverse">
              <mat-icon svgIcon="airplane-outline-2" color="primary" />
            </span>
            @if (option?.flight?.returnSegments?.at(0)?.airlineCode | runFunction: this.getAirlineIconFn; as icon) {
              <mat-icon class="svg-airline" [svgIcon]="icon" />
            }
            <div>
              {{ option?.flight?.returnSegments?.at(0)?.flightCode }}
              {{ option?.flight?.returnSegments?.at(0)?.departureAirportCode }}
              <span class="font-semibold">{{
                option?.flight?.returnSegments?.at(0)?.departureFlightDate | date: 'dd.MM HH:mm'
              }}</span>
              -
              {{ option?.flight?.returnSegments?.at(-1)?.arrivalAirportCode }}
              <span class="font-semibold">{{
                option?.flight?.returnSegments?.at(-1)?.arrivalFlightDate | date: 'dd.MM HH:mm'
              }}</span>
            </div>
            <app-kz-flight-class
              [flightClass]="option.flight?.returnSegments?.at(0)?.class"
              [ticketsLeft]="option.flight?.returnTicketsLeft"
              [isBlock]="option.flight?.isBlock"
            />
          </div>
        </div>
        @if (option.priceDifference && !selectView) {
          <div
            class="flex items-center"
            [ngClass]="{ 'text-kz-success': option.priceDifference < 0, 'text-kz-danger': option.priceDifference > 0 }"
          >
            {{ option.priceDifference > 0 ? '+' : '' }}{{ option.priceDifference | kzPrice: option.priceCurrency }}
          </div>
        }
      </div>
    </ng-template>
  </div>
}

<!-- Templates -->
<ng-template #bagageSelectOptionTemplateRef let-option="option" [typedTemplate]="bagageSelectorTemplateTypedToken">
  <div class="flex flex-row justify-between w-full">
    {{ option.description }} {{ option.price ?? 0 | kzPrice: option.currency }}
  </div>
</ng-template>
