import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
import { MobileHotelFilterMobileFormComponent } from '@pages/_common/mobile-hotel-filter-mobile-form/mobile-hotel-filter-mobile-form.component';
import { MobileNumberOfPassengersFormComponent } from '@pages/_common/mobile-number-of-passengers-form/mobile-number-of-passengers-form.component';
import { MobilePriceAndServicesFormComponent } from '@pages/_common/mobile-price-and-services-form/mobile-price-and-services-form.component';
import { ToursBookingInstructionsComponent } from '@pages/tours-page/desktop/tours-search-desktop-form/tours-booking-instructions/tours-booking-instructions.component';
import { KzDesktopHotelCardComponent } from '@shared/components/desktop/_elements/kz-desktop-hotel-card/kz-desktop-hotel-card.component';
import { KzMobileHotelCardComponent } from '@shared/components/desktop/_elements/kz-mobile-hotel-card/kz-mobile-hotel-card.component';
import { KzDesktopButtonPopupComponent } from '@shared/components/desktop/kz-desktop-button-popup/kz-desktop-button-popup.component';
import { KzDesktopDatePickerComponent } from '@shared/components/desktop/kz-desktop-date-picker/kz-desktop-date-picker.component';
import { KzDesktopLoadingWindowComponent } from '@shared/components/desktop/kz-desktop-loading-window/kz-desktop-loading-window.component';
import { KzDesktopLoadingWindowService } from '@shared/components/desktop/kz-desktop-loading-window/kz-desktop-loading-window.service';
import { KzDesktopMultiSelectBoxComponent } from '@shared/components/desktop/kz-desktop-multi-select-box/kz-desktop-multi-select-box.component';
import { KzDesktopMultiSelectComponent } from '@shared/components/desktop/kz-desktop-multi-select/kz-desktop-multi-select.component';
import { KzDesktopNumberPickerComponent } from '@shared/components/desktop/kz-desktop-number-picker/kz-desktop-number-picker.component';
import { KzDesktopPaginationComponent } from '@shared/components/desktop/kz-desktop-pagination/kz-desktop-pagination.component';
import { KzDesktopSelectComponent } from '@shared/components/desktop/kz-desktop-select/kz-desktop-select.component';
import { KzCurrenciesInfoComponent } from '@shared/components/kz-currencies-info/kz-currencies-info.component';
import { KzFlightClassComponent } from '@shared/components/kz-flight-class/kz-flight-class.component';
import { KzMessageBoxComponent } from '@shared/components/kz-message-box/kz-message-box.component';
import { KzStarsComponent } from '@shared/components/kz-stars/kz-stars.component';
import { KzMobileDatePickerComponent } from '@shared/components/mobile/kz-mobile-date-picker/kz-mobile-date-picker.component';
import { KzMobileInputFormComponent } from '@shared/components/mobile/kz-mobile-input-form/kz-mobile-input-form.component';
import { KzMobileMultiSelectBoxComponent } from '@shared/components/mobile/kz-mobile-multi-select-box/kz-mobile-multi-select-box.component';
import { KzMobileMultiSelectComponent } from '@shared/components/mobile/kz-mobile-multi-select/kz-mobile-multi-select.component';
import { KzMobileSearchButtonComponent } from '@shared/components/mobile/kz-mobile-search-button/kz-mobile-search-button.component';
import { KzMobileSelectComponent } from '@shared/components/mobile/kz-mobile-select/kz-mobile-select.component';
import { KzButtonLoadingDirective } from '@shared/directives/kz-button-loading.directive';
import { KzInputDirective } from '@shared/directives/kz-input.directive';
import { TypedTemplateDirective } from '@shared/directives/typed-template.directive';
import { DynamicModalModule } from '@shared/modules/dynamic-modal/dynamic-modal.module';
import { DynamicOverlayModule } from '@shared/modules/dynamic-overlay/dynamic-overlay.module';
import { KzMobileTabViewModule } from '@shared/modules/kz-mobile-tab-view/kz-mobile-tab-view.module';
import { KzPricePipe } from '@shared/pipes/kz-price.pipe';
import { OrNullPipe } from '@shared/pipes/or-null.pipe';
import { ParseIntPipe } from '@shared/pipes/parse-int.pipe';
import { RunFunctionPipe } from '@shared/pipes/run-function.pipe';
import { SnakeCasePipe } from '@shared/pipes/snake-case.pipe';
import { TourDetailsDesktopComponent } from './desktop/tour-details-desktop/tour-details-desktop.component';
import { ToursDesktopPageComponent } from './desktop/tours-desktop-page/tours-desktop-page.component';
import { ToursResultDesktopComponent } from './desktop/tours-result-desktop/tours-result-desktop.component';
import { ToursSearchDesktopFormComponent } from './desktop/tours-search-desktop-form/tours-search-desktop-form.component';
import { TourDetailsMobileComponent } from './mobile/tour-details-mobile/tour-details-mobile.component';
import { ToursMobilePageComponent } from './mobile/tours-mobile-page/tours-mobile-page.component';
import { ToursResultMobileComponent } from './mobile/tours-result-mobile/tours-result-mobile.component';
import { ToursSearchMobileFormComponent } from './mobile/tours-search-mobile-form/tours-search-mobile-form.component';
import { ToursApiService } from './services/tours-api.service';
import { ToursCommonService } from './services/tours-common.service';
import { ToursSearchFormService } from './services/tours-search-form.service';
import { ToursService } from './services/tours.service';
import { ToursState } from './state/tours.state';
import { ToursPageRoutingModule } from './tours-page-routing.module';

@NgModule({
  declarations: [
    TourDetailsDesktopComponent,
    TourDetailsMobileComponent,
    ToursDesktopPageComponent,
    ToursMobilePageComponent,
    ToursResultDesktopComponent,
    ToursResultMobileComponent,
    ToursSearchDesktopFormComponent,
    ToursSearchMobileFormComponent,
    ToursBookingInstructionsComponent
  ],
  imports: [
    CommonModule,
    KzDesktopButtonPopupComponent,
    KzDesktopDatePickerComponent,
    /*  */
    KzDesktopNumberPickerComponent,
    KzDesktopSelectComponent,
    /*  */
    MatCheckboxModule,
    MatProgressSpinnerModule,
    ToursPageRoutingModule,
    KzDesktopMultiSelectBoxComponent,
    TranslateModule,
    TypedTemplateDirective,
    KzDesktopMultiSelectComponent,
    KzInputDirective,
    KzButtonLoadingDirective,
    KzDesktopLoadingWindowComponent,
    KzStarsComponent,
    KzPricePipe,
    SnakeCasePipe,
    KzDesktopPaginationComponent,
    KzDesktopHotelCardComponent,
    /*  */
    KzMobileHotelCardComponent,
    KzMobileSelectComponent,
    KzMobileDatePickerComponent,
    KzMobileInputFormComponent,
    KzMobileMultiSelectComponent,
    KzMobileMultiSelectBoxComponent,
    KzMobileTabViewModule,
    KzMobileSearchButtonComponent,
    KzCurrenciesInfoComponent,
    MobileNumberOfPassengersFormComponent,
    MobileHotelFilterMobileFormComponent,
    MobilePriceAndServicesFormComponent,
    KzMessageBoxComponent,
    KzFlightClassComponent,
    MatTooltipModule,
    DynamicOverlayModule,
    /*  */
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    ParseIntPipe,
    OrNullPipe,
    RunFunctionPipe,
    NgxsModule.forFeature([ToursState]),
    NgxsFormPluginModule,
    /*  */
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    MatProgressBarModule,
    CdkScrollable
  ],
  providers: [
    DynamicModalModule,
    KzDesktopLoadingWindowService,
    ToursApiService,
    ToursCommonService,
    ToursSearchFormService,
    ToursService
  ]
})
export class ToursPageModule {}
