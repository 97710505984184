import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IS_MOBILE } from '@core/misc/misc.utils';
import { ToursDesktopPageComponent } from './desktop/tours-desktop-page/tours-desktop-page.component';
import { ToursMobilePageComponent } from './mobile/tours-mobile-page/tours-mobile-page.component';

const routes: Routes = [{ path: '', component: IS_MOBILE ? ToursMobilePageComponent : ToursDesktopPageComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ToursPageRoutingModule {}
