<div class="rounded-xl bg-white mt-7 mb-24 min-h-full">
  @if (searchResults.loadState.status !== 'error') {
    @if (searchResults.response?.pagingId) {
      <div class="flex items-center page-container my-4 p-[0!important]">
        <app-kz-desktop-pagination
          [isLoading]="searchResults.loadState.status === 'loading'"
          [currentPage]="searchResults.response?.pageNumber ?? 1"
          [pagesCount]="searchResults.response?.pageCount ?? 1"
          [totalCount]="searchResults.response?.totalCount"
          [selectedRawCount]="service.pageRowCount"
          [sortableColumns]="sortableColumns"
          [onlyPaging]="false"
          (selectPageRawCount)="
            onPerPageChange({
              pageNumber: 1,
              pagingId: searchResults.response?.pagingId,
              pageRowCount: $event
            })
          "
          (sortColumn)="
            sortColumn({
              pageNumber: searchResults.response?.pageNumber,
              pageRowCount: service.pageRowCount(),
              pagingId: searchResults.response?.pagingId,
              sortByField: $event.sortByField,
              sortOrder: $event.sortOrder
            })
          "
        />
      </div>
    }
  }
  @if (searchResults.loadState.status === 'loading' || searchResults.loadState.status === 'error') {
    <div class="p-4">
      <div class="w-full bg-color bg-kz-light-blue-100 rounded block relative overflow-hidden">
        @if (searchResults.loadState.status === 'loading') {
          <div class="p-2 text-secondary">
            {{ 'Search results...' | translate }}
          </div>
        }
        @if (searchResults.loadState.errorMessage) {
          <div class="p-2 text-kz-danger">
            {{ searchResults.loadState.errorMessage | translate }}
          </div>
        }
        @if (searchResults.loadState.status === 'loading') {
          <mat-progress-bar mode="indeterminate" color="accent" />
        }
      </div>
    </div>
  }
  <div class="mt-5 h-full w-full">
    @if (searchResults.loadState.status === 'loading') {
      <ng-container [ngTemplateOutlet]="skeletonItems" />
    } @else {
      @if (searchResults.response?.packages?.length) {
        <div class="pt-7 px-1">
          <div
            class="w-full py-3 border rounded-xl bg-kz-light-blue-50 flex flex-row text-2xs align-center justify-center gap-2"
          >
            <div class="flex gap-2 items-center">
              <div class="square free_rale"></div>
              <span>{{ 'Available Rooms' | translate }}</span>
            </div>
            <div class="flex gap-2 items-center">
              <div class="square on_request"></div>
              <span>{{ 'Upon request' | translate }}</span>
            </div>
            <div class="flex gap-2 items-center">
              <div class="square stop_sale"></div>
              <span>{{ 'Suspended sales' | translate }}</span>
            </div>
          </div>
          <div
            class="w-full py-1 border rounded-xl bg-kz-light-blue-50 flex flex-row text-2xs align-center justify-center gap-2 mt-3"
          >
            <div class="flex flex-col gap-1 items-center">
              <mat-icon class="plane free_sale" svgIcon="hotels-results-plane" />
              <span>{{ 'Places Available' | translate }}</span>
            </div>
            <div class="flex flex-col gap-1 items-center">
              <mat-icon class="plane gds" svgIcon="hotels-results-plane" />
              <span>{{ 'GDS' | translate }}</span>
            </div>
            <div class="flex flex-col gap-1 items-center">
              <mat-icon class="plane stop_sale" svgIcon="hotels-results-plane" />
              <span>{{ 'Suspended sales' | translate }}</span>
            </div>
            <div class="flex flex-col gap-1 items-center">
              <mat-icon class="plane on_request" svgIcon="hotels-results-plane" />
              <span>{{ 'Upon request' | translate }}</span>
            </div>
            <div class="flex flex-col gap-1 items-center">
              <mat-icon class="plane warning few_places" svgIcon="hotels-results-plane" />
              <span>{{ 'Few seats' | translate }}</span>
            </div>
          </div>
        </div>
        <div class="p-4 mt-3">
          @for (item of searchResults.response?.packages; track item.id; let index = $index) {
            <ng-container [ngTemplateOutlet]="itemTemplateRef" [ngTemplateOutletContext]="{ item, index }" />
          }
        </div>
        <div class="flex align-center justify-center pb-5">
          <app-kz-desktop-pagination
            [isLoading]="searchResults.loadState.status?.includes('loading')"
            [currentPage]="searchResults.response?.pageNumber ?? 1"
            [pagesCount]="searchResults.response?.pageCount ?? 1"
            (selectPage)="
              onPageSelect({
                pageNumber: $event,
                pageRowCount: service.pageRowCount(),
                pagingId: searchResults.response?.pagingId
              })
            "
          />
        </div>
      }
    }
  </div>
  <ng-template #results>
    @if (searchResults.response?.packages?.length) {
      <div class="pt-7 px-1">
        <div
          class="w-full py-3 border rounded-xl bg-kz-light-blue-50 flex flex-row text-2xs align-center justify-center gap-2"
        >
          <div class="flex gap-2 items-center">
            <div class="square free_rale"></div>
            <span>{{ 'Available Rooms' | translate }}</span>
          </div>
          <div class="flex gap-2 items-center">
            <div class="square on_request"></div>
            <span>{{ 'Upon request' | translate }}</span>
          </div>
          <div class="flex gap-2 items-center">
            <div class="square stop_sale"></div>
            <span>{{ 'Suspended sales' | translate }}</span>
          </div>
        </div>
        <div
          class="w-full py-1 border rounded-xl bg-kz-light-blue-50 flex flex-row text-2xs align-center justify-center gap-2 mt-3"
        >
          <div class="flex flex-col gap-1 items-center">
            <mat-icon class="plane free_sale" svgIcon="hotels-results-plane" />
            <span>{{ 'Places Available' | translate }}</span>
          </div>
          <div class="flex flex-col gap-1 items-center">
            <mat-icon class="plane stop_sale" svgIcon="hotels-results-plane" />
            <span>{{ 'Suspended sales' | translate }}</span>
          </div>
          <div class="flex flex-col gap-1 items-center">
            <mat-icon class="plane on_request" svgIcon="hotels-results-plane" />
            <span>{{ 'Upon request' | translate }}</span>
          </div>
          <div class="flex flex-col gap-1 items-center">
            <mat-icon class="plane warning few_places" svgIcon="hotels-results-plane" />
            <span>{{ 'Few seats' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="p-4 mt-3">
        @for (item of searchResults.response?.packages; track item.id) {}
      </div>
    }
  </ng-template>
  <ng-template #skeletonItems>
    <div class="p-4">
      @for (items of [].constructor(4); track index; let index = $index) {
        <div>
          <div class="rounded-sm h-[12rem] w-full animate-pulse bg-kz-grey-100"></div>
          <div class="rounded-sm h-[2rem] w-full mt-2 animate-pulse bg-kz-grey-100"></div>
        </div>
      }
    </div>
  </ng-template>
</div>
<app-kz-currencies-info />
<ng-template #childItemsViewTemplateRef>
  <div class="p-2">
    @for (item of selectedChildren; track item.id; let index = $index) {
      <ng-container [ngTemplateOutlet]="itemTemplateRef" [ngTemplateOutletContext]="{ item, index, isChild: true }" />
    }
  </div>
</ng-template>
<ng-template #itemTemplateRef let-item="item" let-index="index" [typedTemplate]="itemsTypedTemplateToken">
  <div class="mb-10">
    <div
      class="rounded text-sm bg-kz-layout-featured availability-border-color"
      [ngClass]="item.hotel?.availabilityType | snakeCase"
    >
      <div class="flex flex-row border-bottom justify-between items-start gap-2">
        <div class="flex flex-col p-2 gap-1 w-[65%]" [style.color]="item.hotel?.hotelColor">
          <div class="font-semibold">
            {{ item?.hotel?.hotelName }}
          </div>
          <div class="text-secondary text-2xs mt-1">
            {{ item?.hotel?.cityName }},
            {{ item?.hotel?.districtName }}
          </div>
          @if (item.subList && item.subList.length) {
            <button
              type="button"
              mat-flat-button
              color="primary"
              class="btn-collapse"
              style="height: 24px !important"
              (click)="onOpenChildren(item.subList)"
            >
              {{ 'All prices of this hotel' | translate }}
              <span class="collapse-icon">
                <mat-icon class="icon" svgIcon="chevron" />
              </span>
            </button>
          }
        </div>
        <div class="pt-2 w-[35%] flex justify-end gap-5">
          <app-kz-stars class="pt-2" color="primary-dark" [stars]="item?.hotel?.hotelClass ?? 0" />
          <div
            class="rounded-l-xl w-max right-0 p-2 h-3rem text-white font-semibold availability-bg-color"
            [ngClass]="item.hotel?.availabilityType | snakeCase"
          >
            {{ item.totalPrice | kzPrice: item.priceCurrency }}
          </div>
        </div>
      </div>
      <div class="border-bottom p-2">
        <div class="flex justify-between">
          <div>
            <div class="flex gap-1">
              <span class="font-semibold">{{ item.flight?.departureSegments?.at(0)?.airlineName }} </span>

              <app-kz-flight-class
                [flightClass]="item.flight?.departureSegments?.at(0)?.class"
                [ticketsLeft]="item.flight?.departureTicketsLeft"
                [isBlock]="item.flight?.isBlock"
              />
            </div>
            <span class="text-2xs">
              {{ item.flight?.departureSegments?.at(0)?.flightCode }}
            </span>
          </div>
          <div class="ml-auto result-avia-icons">
            <mat-icon
              class="plane"
              svgIcon="hotels-results-plane"
              [ngClass]="item.flight?.departureAvailabilityType | snakeCase"
            />
          </div>
        </div>
        <div class="flex justify-between">
          <div>
            <div class="flex gap-1">
              <span class="font-semibold">{{ item.flight?.returnSegments?.at(0)?.airlineName }} </span>

              <app-kz-flight-class
                [flightClass]="item.flight?.returnSegments?.at(0)?.class"
                [ticketsLeft]="item.flight?.returnTicketsLeft"
                [isBlock]="item.flight?.isBlock"
              />
            </div>
            <span class="text-2xs">
              {{ item.flight?.returnSegments?.at(0)?.flightCode }}
            </span>
          </div>
          <div class="ml-auto result-avia-icons">
            <mat-icon
              class="plane inverse"
              svgIcon="hotels-results-plane"
              [ngClass]="item.flight?.returnAvailabilityType | snakeCase"
            />
          </div>
        </div>
      </div>
      <div class="p-2">
        <div>
          {{ item.hotel?.checkInDate | date }}
          -
          {{ item.hotel?.checkOutDate | date }} ({{ item.hotel?.nights }} {{ 'Nights' | translate }})
        </div>
        <div>
          {{ item?.hotel?.roomCategoryName }}
          @if (item?.hotel?.mealPlan) {
            <span>, {{ item?.hotel?.mealPlan }}</span>
          }
        </div>
      </div>
    </div>
    <div class="mt-2">
      <button type="button" class="w-full" mat-flat-button color="secondary" (click)="onOpenDetails(item)">
        <mat-icon color="primary-dark" svgIcon="cart" />
        <span class="text-kz-primary-dark">
          {{ 'Add to cart' | translate }}
        </span>
      </button>
    </div>
  </div>
</ng-template>
