<div class="relative">
  <div class="p-4">
    <div>
      <p class="mb-5">
        Стандартный тур пакет (<a
          class="font-semibold"
          href="https://kazunion.com/новый-онлайн-стандартный-тур-пакет/"
          target="_blank"
          >ссылка</a
        >)
      </p>
      <p class="mb-1">
        Комбинированные туры (<a
          class="font-semibold"
          href="https://kazunion.com/комбинирование-туров-в-новом-онлайне/"
          target="_blank"
          >ссылка</a
        >)
      </p>

      <div class="my-3">
        <p class="font-semibold">Стоимость тура включает:</p>
        <ul class="m-0">
          <li>международный авиаперелёт выбранной авиакомпанией</li>
          <li>групповой трансфер аэропорт–отель–аэропорт</li>
          <li>проживание в выбранном отеле с указанием типа питания</li>
          <li>стандартная комиссия агентства</li>
          <li>страховка (добровольная)</li>
        </ul>
      </div>

      <div>
        <p class="font-semibold">Дополнительно оплачивается:</p>
        <ul class="m-0">
          <li>трансфер отель-отель (при бронировании комбинированных туров)</li>
          <li>
            стоимость визы - 85 USD за человека для граждан Кыргызстана, Узбекистана, Таджикистана, Туркменистана,
            Азербайджана, Армении, Грузии, Белоруси (гражданам Казахстана, России и Украины виза по прибытию -
            бесплатно)
          </li>
          <li>доплата за ребёнка до 1,99 лет без места в самолете - 90 USD</li>
          <li>
            обязательный Гала-ужин (информация о доплате за Гала-ужин в разделе "Стоимость Гала-ужина"). ВАЖНО! Отели,
            отсутствующие в списке, необходимо запросить отдельно, написав запрос на почту uae&#64;kazunion.com
          </li>
          <li>индивидуальные трансферы на транспортных средствах по желанию и на выбор туриста</li>
          <li>
            аэропорт сервис по встрече/проводам на прилёте/вылете в аэропортах DXB/SHJ/AUH по желанию и на выбор туриста
          </li>
          <li>доплата за выбор места на борту (по желанию)</li>
          <li>доплата за доп. багаж (по желанию)</li>
          <li>доплата за выбор питания на борту (а/к Fly Dubai) (по желанию)</li>
        </ul>
      </div>
    </div>
  </div>
</div>
