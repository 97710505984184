import { Component } from '@angular/core';
import { StoreService } from '@core/store/store.service';
import { ToursState } from '@pages/tours-page/state/tours.state';

@Component({
  selector: 'app-tours-desktop-page',
  templateUrl: './tours-desktop-page.component.html',
  styleUrl: './tours-desktop-page.component.scss'
})
export class ToursDesktopPageComponent {
  public readonly searchResults$ = this.storeService.select(ToursState.searchResults);
  constructor(private storeService: StoreService) {}
}
