import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchTourPackagesResponse, TourPackage } from '@appTypes/api.types';
import { ITourPackage } from '@appTypes/redefined-types';
import { collapseAnimation } from '@core/misc/animations.misc';
import { RequestStateModel } from '@core/store/store.models';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { ToursSearchFormService } from '@pages/tours-page/services/tours-search-form.service';
import { toursOpenDetails, toursSearchTourPackages } from '@pages/tours-page/state/tours.actions';
import { SearchTourPackagesPaging, ToursColumnsSort, ToursSortableColumnsType } from '@pages/tours-page/tours.types';

@Component({
  selector: 'app-tours-result-desktop',
  templateUrl: './tours-result-desktop.component.html',
  styleUrl: './tours-result-desktop.component.scss',
  animations: [collapseAnimation()]
})
export class ToursResultDesktopComponent {
  @Input() public searchResults!: RequestStateModel<SearchTourPackagesResponse>;
  public columnSortOrders?: ToursColumnsSort;
  public collapsed: number | null = null;
  public readonly itemsTypedTemplateToken!: { item: TourPackage; isChild?: boolean; index: number };

  constructor(
    private snackBar: MatSnackBar,
    private storeService: StoreService,
    public service: ToursSearchFormService,
    private translateService: TranslateService
  ) {}

  public onCollapse(index: number) {
    if (this.collapsed === index) this.collapsed = null;
    else this.collapsed = index;
  }
  public onPageSelect(paging: SearchTourPackagesPaging) {
    this.storeService.dispatch(new toursSearchTourPackages({ ...paging, ...this.columnSortOrders }));
    this.collapsed = null;
  }
  public onOpenDetails(data: ITourPackage) {
    this.storeService.dispatch(new toursOpenDetails(data));
    this.collapsed = null;
  }
  public onPerPageChange(paging: SearchTourPackagesPaging) {
    this.service.pageRowCount.set(paging.pageRowCount ?? 25);
    this.storeService.dispatch(new toursSearchTourPackages({ ...paging, ...this.columnSortOrders }));
    this.collapsed = null;
  }
  public sortColumn(sortByField: ToursSortableColumnsType) {
    this.collapsed = null;
    if (this.searchResults.loadState.status === 'loading') {
      this.snackBar.open(
        this.translateService.instant('Data is already in the loading process. Please wait until it is fully loaded.'),
        '',
        {
          panelClass: 'snackbar-warning',
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        }
      );
      return;
    }
    this.columnSortOrders = {
      sortByField,
      sortOrder:
        this.columnSortOrders?.sortByField === sortByField && this.columnSortOrders?.sortOrder === 'ASC'
          ? 'DESC'
          : 'ASC'
    };
    this.storeService.dispatch(
      new toursSearchTourPackages({
        pageNumber: this.searchResults.response?.pageNumber,
        pageRowCount: this.service.pageRowCount(),
        pagingId: this.searchResults.response?.pagingId,
        ...this.columnSortOrders
      })
    );
  }
}
