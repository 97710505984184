export const NGXS_TOURS_PERSISTENT_STATE_KEYS: string[] = ['tours.searchForm'];
export const NGXS_TOURS_SEARCH_FORM = 'tours.searchForm';
export const TOUR_DIRECT_FLIGHT_CHECKBOX_HIDE_FOR_ARRIVAL_DIRECTIONS: string[] = ['MU'];
export const TOUR_COLUMNS_STATIC_DATA = [
  { value: 'SortCheckInOut', label: 'Check-in date' },
  { value: 'SortAirline', label: 'Airline' },
  { value: 'SortHotelName', label: 'Hotel name' },
  { value: 'SortDetails', label: 'Details' },
  { value: 'SortPlacement', label: 'Rooms and capacity' },
  { value: 'TotalPrice', label: 'Price' }
];
