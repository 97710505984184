import { DialogRef } from '@angular/cdk/dialog';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchTourPackagesResponse } from '@appTypes/api.types';
import { ITourPackage } from '@appTypes/redefined-types';
import { RequestStateModel } from '@core/store/store.models';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { ToursSearchFormService } from '@pages/tours-page/services/tours-search-form.service';
import { toursOpenDetails, toursSearchTourPackages } from '@pages/tours-page/state/tours.actions';
import { TOUR_COLUMNS_STATIC_DATA } from '@pages/tours-page/tours-page.const';
import { SearchTourPackagesPaging, ToursColumnsSort } from '@pages/tours-page/tours.types';
import { DynamicModalService } from '@shared/modules/dynamic-modal/dynamic-modal.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-tours-result-mobile',
  templateUrl: './tours-result-mobile.component.html',
  styleUrl: './tours-result-mobile.component.scss'
})
export class ToursResultMobileComponent {
  @ViewChild('childItemsViewTemplateRef') childItemsViewTemplateRef!: TemplateRef<unknown>;

  @Input() public searchResults!: RequestStateModel<SearchTourPackagesResponse>;
  public columnSortOrders?: ToursColumnsSort;
  public sortableColumns = TOUR_COLUMNS_STATIC_DATA;
  public readonly itemsTypedTemplateToken!: { item: ITourPackage; isChild?: boolean; index: number };

  public selectedChildren: ITourPackage[] = [];

  constructor(
    private storeService: StoreService,
    public service: ToursSearchFormService,
    private translateService: TranslateService,
    private dynamicModalService: DynamicModalService,
    private snackBar: MatSnackBar
  ) {}
  public onOpenChildren(selectedChildren: ITourPackage[]) {
    this.selectedChildren = selectedChildren;
    const modalRef = this.dynamicModalService.open({
      title: 'All prices of this hotel',
      template: this.childItemsViewTemplateRef,
      height: '85vh'
    }) as DialogRef;
    modalRef.closed.pipe(take(1)).subscribe(() => (this.selectedChildren = []));
  }
  public onPageSelect(paging: SearchTourPackagesPaging) {
    window.scrollTo(0, 0);
    this.storeService.dispatch(new toursSearchTourPackages({ ...paging, ...this.columnSortOrders }));
  }

  public onOpenDetails(data: ITourPackage) {
    this.storeService.dispatch(new toursOpenDetails(data));
  }
  public onPerPageChange(paging: SearchTourPackagesPaging) {
    this.service.pageRowCount.set(paging.pageRowCount ?? 25);
    this.storeService.dispatch(new toursSearchTourPackages({ ...paging, ...this.columnSortOrders }));
  }
  public sortColumn(sort: SearchTourPackagesPaging) {
    if (this.searchResults.loadState.status === 'loading') {
      this.snackBar.open(
        this.translateService.instant('Data is already in the loading process. Please wait until it is fully loaded.'),
        '',
        {
          panelClass: 'snackbar-warning',
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        }
      );
      return;
    }

    this.columnSortOrders = {
      sortByField: sort.sortByField as ToursColumnsSort['sortByField'],
      sortOrder: sort.sortOrder as ToursColumnsSort['sortOrder']
    };
    this.storeService.dispatch(new toursSearchTourPackages(sort));
  }
}
